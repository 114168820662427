import {create} from 'zustand'
import {colors} from './colors'

interface WakeyState {
  headerColor: string
  setHeaderColor: (color: string) => void
  headerRootMargin: string | null
  setHeaderRootMargin: (margin: string) => void
}

export const useStore = create<WakeyState>((set) => ({
  headerColor: colors.sand,
  setHeaderColor: (headerColor) => set({headerColor}),
  headerRootMargin: null,
  setHeaderRootMargin: (headerRootMargin) => set({headerRootMargin}),
}))
