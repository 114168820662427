export const colors = {
  skyblue: '#99BDFF',
  blue: '#D4E8FF',
  white: '#fff',
  yellow: '#FFFF00',
  ocher: '#E3B012',
  softorange: '#FAD103',
  sand: '#FFF5EB',
  black: '#000000',
}
