import clsx from 'clsx'
import {Link} from './link'

export default function Button({data, className, children, ...props}: any) {
  const {type} = data

  const defaulClasses = 'text-[1.6rem] leading-[80%]  w-fit'
  const Component = data?.link ? Link : data?.externalLink ? 'a' : 'button'

  return (
    <Component
      to={data.link}
      className={clsx(
        type == 'primary' &&
          'bg-ocher tracking-pn-3 ease-wakey border-ocher md:hover:bg-yellow group inline-flex h-[5.4rem] items-center justify-center rounded-full px-[4.4rem] transition-all duration-300 md:hover:text-black',
        type == 'secondary' && 'tracking-pn-1 group -my-4 inline-block py-4 pr-4 uppercase',
        defaulClasses,
        className
      )}
      {...props}
    >
      <span className="">{children ? children : data.text}</span>
      {type == 'secondary' && (
        <span className="md:ease-wakey mt-[.15rem] block h-[.1rem] max-w-full bg-black transition-all duration-500 md:group-hover:max-w-0" />
      )}
    </Component>
  )
}
