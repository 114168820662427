import PortableText from 'react-portable-text'

export default function RichText({
  data,
  className,
  textClassName,
}: {
  data: any
  className?: string
  textClassName?: string
}) {
  return (
    <>
      <PortableText
        className={className}
        content={data}
        serializers={{
          alternative: ({children}: any) => <p className="">{children}</p>,
          strong: ({children}: any) => {
            return <strong className="">{children}</strong>
          },
          em: ({children}: any) => <em className="">{children}</em>,
          normal: ({children}: any) => <p className={textClassName}>{children}</p>,
        }}
      />
    </>
  )
}
